import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const SponsorPlug = ({ logo }) => {
  // to ignore default width when using fixed height in SponsorLockup fields
  const useDefault = logo.logoImage.gatsbyImageData.layout !== 'fixed';
  const sponsorLogoImage = (
    <GatsbyImage
      alt={logo.logoImage && logo.logoImage.description === ''
        ? 'Sponsor logo'
        : logo.logoImage.description}
      className="sponsor-logo"
      image={getImage(logo.logoImage)}
      imgStyle={{ objectFit: 'contain', padding: '1px' }}
      style={useDefault ? { maxWidth: logo.width || 125 } : null}
    />
  );
  // If the logo has a URL, wrap the logo image in a link.
  if (logo.logoImage && logo.url) {
    return <a href={logo.url} target={logo.newTab === true ? '_blank' : '_self'} rel="noreferrer">{sponsorLogoImage}</a>;
  }

  // Otherwise assume we should just display the logo image
  return sponsorLogoImage;
};

SponsorPlug.propTypes = {
  // TODO: Fix this eslint warning and remove whitelisting
  // eslint-disable-next-line react/forbid-prop-types
  logo: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default SponsorPlug;
