/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import CardButton from '@components/CardButton';
import CTABanner from '@components/CTABanner';
import HomeCard from '@components/HomeCard';
import HeroImage from '@components/HeroImage';
import parseTitle from '@utils/parseTitle';
import '@styles/home.scss';

const HomepageView = ({ data }) => {
  const {
    cardSection, featuredSection, homePageBanner, subscribeMessage,
  } = data;

  return (
    <>
      <div className="home-container" data-cy="home-container">
        <HeroImage
          buttonText={homePageBanner.buttonText}
          image={homePageBanner.banner}
          link={homePageBanner.link}
          subhead={homePageBanner.subtitle}
          title={homePageBanner.titleBanner}
          logos={homePageBanner.sponsorLogos}
        />
        <CTABanner type="login" label={subscribeMessage} />
        {(featuredSection?.cards.length || cardSection?.cards.length) && (
          <Container>
            <Row className="home-row-top">
              {/* eslint-disable-next-line */}
              {featuredSection?.cards.filter(card => card.__typename === 'ContentfulContentCard').map((card, index) => (
                <Col
                  sm={{ size: 12, order: index }}
                  lg={{ size: 6, order: index }}
                  key={`featureCard_${index}`}
                >
                  <HomeCard
                    ariaLabel={`Read more about how to ${parseTitle(
                      card.titleDisplay,
                    )}`}
                    body={card.body}
                    buttonText={card.button?.buttonText}
                    buttonUrl={card.button?.buttonUrl}
                    imgSrc={card.cardImage}
                    altText={card.cardImage.description}
                    title={card.titleDisplay}
                  />
                </Col>
              ))}
            </Row>
            <Row className="home-row-bottom">
              {/* eslint-disable-next-line */}
              {cardSection?.cards.filter(card => card.__typename === 'ContentfulCardLink').map((card, index) => (
                <Col
                  sm={{ size: 12, order: index }}
                  lg={{ size: 6, order: index }}
                  key={`cardSection_${index}`}
                >
                  <CardButton
                    altText={card.banner.description}
                    ariaLabel={`Read more about how to ${card.titleDisplay}`}
                    body={card.tagline}
                    imgSrc={card.banner}
                    link={card.link}
                    title={card.titleDisplay}
                    titleSize="h2"
                  />
                </Col>
              ))}
            </Row>
          </Container>
        )}
      </div>
    </>
  );
};

HomepageView.propTypes = {
  // TODO: Fix this eslint warning and remove whitelisting
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default HomepageView;
