import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import PropTypes from 'prop-types';
import React from 'react';
import {
  CardBody, Button,
} from 'reactstrap';
import Title from '@components/Title';
import Link from '@components/Link';

const HomeCardBodyContent = ({
  title, altText, imgSrc, body, buttonText, buttonUrl,
}) => (
  <CardBody className="home-card-body">
    <Title title={title} titleSize="h2" classes="mb-0 card-title" cypressDataAttr="home-card-title" />
    <GatsbyImage
      alt={altText}
      data-cy="gatsby-img"
      image={getImage(imgSrc)}
    />
    {body && renderRichText(body)}
    {buttonText && (
      buttonUrl ? <Link to={buttonUrl}><Button size="lg" color="primary">{buttonText}</Button></Link>
        : <Button size="lg" color="primary">{buttonText}</Button>
    )}
  </CardBody>
);

HomeCardBodyContent.defaultProps = {
  body: {},
  buttonText: '',
  buttonUrl: '',
};

HomeCardBodyContent.propTypes = {
  altText: PropTypes.string.isRequired,
  body: PropTypes.shape({
    raw: PropTypes.string,
  }),
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
  // TODO: Fix this eslint warning and remove whitelisting
  // eslint-disable-next-line react/forbid-prop-types
  imgSrc: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string.isRequired,
};

export default HomeCardBodyContent;
