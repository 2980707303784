// custom react hook to check if alertBox data is empty
import { useEffect, useState } from 'react';

/**
 * The object returned by the useAlertBox hook
 * @typedef {Object} UseAlertBoxType
 * @property {boolean} hasAlert - The boolean value of whether or not there is an alert
 * @property {number} alertBoxDatay - The raw alertBox data from the CMS
 */

/**
 * Querys the CMS for the alertBox data and checks if it is empty
 * @returns {UseAlertBoxType}
 */
const useAlertBox = (data) => {
  const [hasAlert, setHasAlert] = useState(false);

  // Raw alert data
  const alertBoxData = data.contentfulHomePage.alertBox;

  useEffect(() => {
    if (!data
      || !data.contentfulHomePage
      || !data.contentfulHomePage.alertBox
      || !data.contentfulHomePage.alertBox.raw) return;
    const alertBoxRawJson = JSON.parse(data.contentfulHomePage.alertBox.raw);
    const alertBoxValueToCheck = alertBoxRawJson.content[0].content[0].value;

    if (alertBoxValueToCheck !== '' && alertBoxValueToCheck !== null && alertBoxValueToCheck !== undefined) {
      setHasAlert(true);
    }
  }, [data]);

  return { hasAlert, alertBoxData };
};

export default useAlertBox;
