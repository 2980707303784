import PropTypes from 'prop-types';
import React from 'react';
import {
  Button, Col, Container, InputGroup, InputGroupText, InputGroupAddon, Input, Row,
} from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

import Link from '@components/Link';

import './index.scss';

const CTABanner = (props) => {
  const { type, label } = props;
  const labelUUID = uuidv4();

  const lfhContent = (
    <Row>
      <Col className="mx-auto" md={8}>
        <h2 id="CTA-Banner-Label">Learn More From Home</h2>
        <p>
          It’s time to get inspired no matter where you
          are. Visit our Learn From Home page for
          more fun activities and educational resources.
        </p>
        <Link to="/learn">
          <Button size="lg" className="btn-cta">
            LEARN MORE
          </Button>
        </Link>
      </Col>
    </Row>
  );

  const subscribeContent = (
    <>
      <Row className="sub-container">
        <Col className="mx-auto subscribe-left" xs={6}>
          {label !== ''
            ? <h2 id="CTA-Banner-Label">{label}</h2>
            : <h2 id="CTA-Banner-Label">Get the latest news about our reopening plans.</h2>}
        </Col>
        <Col className="mx-auto subscribe-right" xs={6}>
          <Link to="https://www.smm.org/subscribe">
            <InputGroup style={{ width: '80%', margin: 'auto' }}>
              <Input placeholder="Email Address" />
              <InputGroupAddon addonType="append">
                <InputGroupText className="btn-cta-group">SUBSCRIBE</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
            {/* only shows in mobile view */}
            <Button size="lg" className="btn-cta-sub">
              SUBSCRIBE
            </Button>
          </Link>
        </Col>
      </Row>
    </>
  );

  const membershipContent = (
    <Row>
      <Col className="mx-auto" md={8}>
        <h2 id={`CTA-Banner-Label-${labelUUID}`}>Become a Member</h2>
        <p>
          Enjoy the museum all year long with free admission
          and Omnitheater movies, special discounts, and exclusive events.
        </p>
        <Link to="http://smm.org/membership">
          <Button size="lg" className="btn-cta">
            Join Today
          </Button>
        </Link>
      </Col>
    </Row>
  );

  const joinOrLoginContent = (
    <Row>
      <Col className="mx-auto" md={8}>
        <p>
          Sign in to your membership account to get tickets, purchase summer camps,
          register for events, and so much more. Not a member? Join us today!
        </p>
        <div>
          <Link to="https://my.smm.org/account/login">
            <Button size="lg" className="btn-cta" style={{ marginRight: '30px', marginBottom: '20px' }}>
              Sign In
            </Button>
          </Link>
          <Link to="http://smm.org/membership">
            <Button size="lg" className="btn-cta" style={{ marginBottom: '20px' }}>
              Become a member
            </Button>
          </Link>
        </div>
      </Col>
    </Row>
  );

  const visitContent = (
    <Row>
      <Col className="mx-auto" md={8}>
        <h2 id={`CTA-Banner-Label-${labelUUID}`}>Science in downtown St. Paul</h2>
        <p>
          Race a T. rex. Test the laws of physics. Trade in a nature find.
          Plan your adventure today by reserving tickets in advance.
        </p>
        <Link to="https://new.smm.org/visit">
          <Button size="lg" className="btn-cta">
            Plan your visit
          </Button>
        </Link>
      </Col>
    </Row>
  );

  let content;

  switch (type) {
    case 'subscribe':
      content = subscribeContent;
      break;
    case 'lfh':
      content = lfhContent;
      break;
    case 'membership':
      content = membershipContent;
      break;
    case 'login':
      content = joinOrLoginContent;
      break;
    case 'visit':
      content = visitContent;
      break;
    default:
      content = lfhContent;
  }

  return (
    <Container
      aria-labelledby="CTA-Banner-Label"
      className="py-5 banner-container"
      data-cy="cta-banner"
      fluid
    >
      {content}
    </Container>
  );
};

CTABanner.defaultProps = {
  type: 'lfh',
  label: '',
};

CTABanner.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
};

export default CTABanner;
