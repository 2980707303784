/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import useAlertBox from '@utils/useAlertBox';
import StandardPageWrapper from '@templates/StandardPageWrapper';
import HeadSEO from '@components/Navigation/HeadSEO';
import HomepageView from '../views/HomepageView';

export const query = graphql`
  query {
    contentfulHomePage(titleDisplay: { eq: "Home Page" }) {
      titleDisplay
      titleSeo
      descriptionSeo
      keywordsSeo
      homePageBanner {
        banner {
          title
          file {
            url
          }
          gatsbyImageData
          description
        }
        subtitle
        buttonText
        link
        sponsorLogos {
          url
          width
          logoImage {
            description
            gatsbyImageData
          }
        }
        titleBanner
      }
      subscribeMessage
      featuredSection {
        copy {
          raw
          references {
            ...RichTextReferences
          }
        }
        cards {
        ...AllCardTypesWide
        ... on ContentfulContentCard {
          id
        }
      }
    }
    cardSection {
      copy {
        raw
        references {
          ...RichTextReferences
        }
      }
      cards {
        ...AllCardTypesWide
        ... on ContentfulCardLink {
          id
        }
      }
    }
    alertBox {
      raw
      references {
        ...RichTextReferences
      }
    }
  }
}
`;

export const Head = ({ data }) => (
  <HeadSEO
    pageDescription={data.contentfulHomePage.descriptionSeo}
    pageKeywords={data.contentfulHomePage.keywordsSeo}
    // TODO: Define where this should live within the CMS
    // There is a titleSEO field that we're using, but it's not
    // clear in the CMS that this will populate the homepage title.
    pageTitle={data.contentfulHomePage.titleSeo}
  />
);

const IndexPage = ({ data }) => {
  const { alertBoxData, hasAlert } = useAlertBox(data);
  return (
    <StandardPageWrapper
      alertBoxData={alertBoxData}
      hasAlert={hasAlert}
      isHomePage={data.contentfulHomePage.titleDisplay === 'Home Page'}
    >
      <HomepageView data={data.contentfulHomePage} />
    </StandardPageWrapper>
  );
};

export default IndexPage;
